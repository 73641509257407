/* Leaderboard.css */
.leaderboard-container {
    margin: 20px 0;
    padding: 20px;
    padding-top: 5px;
    padding-left: 40px;
    padding-right: 40px;
    background-color: #1e1e1e;
    border-radius: 8px;
    color: #e0e0e0;
  }
  
  .leaderboard-container h2 {
    font-family: 'Montserrat', sans-serif;
    font-size: 2rem;
    color: #ffffff;
    margin-bottom: 20px;
  }
  
  .leaderboard-table {
    width: 100%;
    border-collapse: collapse;
    text-align: left;
  }
  
  .leaderboard-table th,
  .leaderboard-table td {
    padding: 12px;
    border-bottom: 1px solid #333333;
  }

  
  .leaderboard-table th {
    font-family: 'Montserrat', sans-serif;
    color: #ffffff;
    font-size: 1.2rem;
  }
  
  .leaderboard-table td {
    font-family: 'Roboto', sans-serif;
    color: #ffffff;
    font-size: 1rem;
  }
  
  /* Default (non-compressed) table styling */
  .leaderboard-table tbody {
    display: table-row-group; /* Default display for tbody in a table */
  }

  .leaderboard-table tr:nth-child(even) {
    background-color: #2c2c2c;
  }
  
  .leaderboard-table tr:hover {
    background-color: #333333;
  }
  
  .current-user-row {
    border: 2px solid #555599; /* Or whatever color you choose */
    border: 2px solid #8884d8; /* Or whatever color you choose */
  }

  th {
    cursor: pointer;
    position: relative;
    padding-right: 20px; /* Ensure space for the arrow on the right */
    font-weight: bold;
  }
  
  th::after {
    content: '';
    position: absolute;
    right: 5px;
    margin-left: -12px;
    top: 50%;
    transform: translateY(-50%);
    width: 0;
    height: 0;
    border-style: solid;
    display: none; /* Hide the arrow initially */
  }
  
  th.ascending::after {
    display: inline-block;
    border-width: 0 7px 10px 7px;
    border-color: transparent transparent #96e89a transparent;
    border-color: transparent transparent #8884d8 transparent;
  }
  
  th.descending::after {
    display: inline-block;
    border-width: 10px 7px 0 7px;
    border-color: #96e89a transparent transparent transparent;
    border-color: #8884d8 transparent transparent transparent;
  }

  /* Leaderboard.css */

.pagination-controls {
  margin-bottom: 10px;
}

.pagination-controls label {
  margin-right: 5px;
  font-weight: bold;
}

.pagination-controls select {
  padding: 5px;
  font-size: 14px;
  margin-right: 10px;
}

.pagination-navigation {
  display: flex;
  justify-content: space-between; /* Spread the buttons and label */
  align-items: center;
  margin-top: 20px;
}

.page-label {
  margin: 0 auto;
  text-align: center;
  flex-grow: 1; /* Ensure the label occupies the remaining space */
  font-weight: bold;
}

.pagination-navigation button {
  padding: 5px 10px;
  font-size: 14px;
  cursor: pointer;
  background-color: #333;
  color: #fff;
  border: none;
  border-radius: 4px;
}

.pagination-navigation button:disabled {
  background-color: #888;
  cursor: not-allowed;
}

.pagination-navigation span {
  font-weight: bold;
}

.fa-copy {
  color: #666;
  transition: color 0.3s;

}

.fa-copy:hover {
  color: #000;
}

.icon-copied {
  animation: copyAnimation 0.5s ease-in-out;
  /*
  color: #96e89a;
  */

}

@keyframes copyAnimation {
  0% { transform: scale(1); }
  50% { transform: scale(1.3); }
  100% { transform: scale(1); }
}

.user-rank {
  font-size: 1.3em;
  font-weight: bold;
  float: right;
}

.leaderboard-top {
  height: 90px;
}

.leaderboard-title{
  float: left;
}

/* Flexbox stacking for small screens */
@media (max-width: 650px) {
  .leaderboard-table {
    display: block;
  }

  .leaderboard-table thead {
    display: none; /* Hide table headers */
  }

  .leaderboard-table tr {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    border: 1px solid #333;
    padding: 10px;
    background-color: #2c2c2c;
  }

  .leaderboard-table td {
    display: flex;
    justify-content: space-between;
    padding: 5px 10px;
    border: none;
    border-bottom: 1px solid #333;
    font-size: 0.9rem;
  }

  .leaderboard-table tbody {
    display: block;
  }

  .leaderboard-table td:last-child {
    border-bottom: none;
  }

  .leaderboard-table td:before {
    content: attr(data-label);
    font-weight: bold;
    color: #8884d8;
    flex-shrink: 0;
  }

  .leaderboard-container {
    padding: 10px; /* Adjust padding for smaller screens */
  }

  .leaderboard-top h2 {
    font-size: 1.4em;
  }
}