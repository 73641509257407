.range-selector {
    display: flex;
    justify-content: center;
    margin-bottom: 40px;
    margin-top:10px;
  }
  
  .range-selector button {
    background-color: #333;
    color: #fff;
    border: 1px solid #444;
    padding: 8px 16px;
    margin: 0 5px;
    cursor: pointer;
    border-radius: 4px;
    transition: background-color 0.3s, border-color 0.3s;
  }
  
  .range-selector button:hover {
    background-color: #444;
    border-color: #666;
  }
  
  .range-selector button:active {
    background-color: #555;
    border-color: #777;
  }
  
  .chart-header {
    background-color: #333; /* Match your chart text color */
    padding: 10px 15px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow */
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
    font-family: Arial, sans-serif; /* Match your chart font */
  }
  
  .chart-header p {
    margin: 0;
    font-size: 1rem; /* Adjust based on your design */
    color: #f9f9f9; /* Light background */
  }
  
  .chart-header .stat-item {
    display: flex;
    align-items: center;
  }
  
  .chart-header .stat-item .icon {
    margin-right: 5px;
  }
  
  .chart-header .stat-item .icon .icon-good {
    color: #96e89a;
    color: #222;
  }

  .chart-header .stat-item .icon .icon-bad {
    color: #222;
    color: #e89696;

  }

  .chart-header .stat-item .value {
    font-weight: bold;
    margin-left: 5px;
  }

  .snail-stats-container {
    background-color: #f4f4f4;
    padding: 15px;
    border-radius: 8px;
    margin-bottom: 20px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .snail-stats-list {
    list-style: none;
    padding: 0;
  }
  
  .snail-stats-list li {
    margin: 8px 0;
    font-size: 16px;
  }

  .snailmints-title {
    margin-left:20px;
  }

  /* Flexbox stacking for small screens */
@media (max-width: 550px) {
  .range-selector button {
    font-size: 10px;
    padding: 4px 4px;
  }
}