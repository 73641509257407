/* Import Google Fonts */
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@600&family=Roboto:wght@300;400&display=swap');

body {
  font-family: 'Roboto', sans-serif;
  background-color: #121212;
  color: #e0e0e0;
  margin: 0;
  padding: 0;
  position: relative;
  overflow-x: hidden;
}

.App {
  max-width: 1200px;
  margin: 20px auto;
  padding: 20px;
  padding-left: 40px;
  padding-right: 40px;
  background-color: rgba(18, 18, 18, 0.9); /* Dark background with slight transparency */
  border-radius: 8px;
  /*box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);*/
  position: relative;
  z-index: 1;
}

@media (max-width: 700px) {
  h1 {
    font-size: 2.9rem; /* Slightly smaller for tablets */
  }
}

/* Top bar styling */
.top-bar {
  display: flex;
  justify-content: flex-end;
  align-items: center; /* Vertically centers the content */
  padding: 10px 20px;
  padding-left:0px;
  background-color: #00000000;
}

/* Wallet button styling */
.wallet-button-container {
  display: flex;
  align-items: center;
}

.wallet-button {
  background-color: #333333;
  color: #ffffff;
  border: 1px solid #444444;
  padding: 10px 20px;
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 1px;
  cursor: pointer;
  border-radius: 4px;
  transition: background-color 0.3s, border-color 0.3s;
}

@media (max-width: 700px) {
  .wallet-button {
    padding: 5px 10px;
    font-size: 11px;
  }
}

.wallet-button:hover {
  background-color: #444444;
  border-color: #666666;
}

.wallet-button-container p {
  margin: 0;
  color: #ffffff;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  letter-spacing: 1px;
}

h1, h2 {
  font-family: 'Montserrat', sans-serif;
  color: #ffffff;
  margin-bottom: 20px;
  letter-spacing: 1px;
}

h1 {
  font-size: 5.5rem;
  font-weight: 600;
  text-transform: uppercase;
}

@media (max-width: 700px) {
  h1 {
    font-size: 2.9rem; /* Slightly smaller for tablets */
  }
}

h2 {
  font-size: 1.8rem;
  font-weight: 600;
}

button {
  background-color: #333333;
  color: #ffffff;
  border: 1px solid #444444;
  padding: 12px 24px;
  font-size: 16px;
  font-family: 'Montserrat', sans-serif;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 4px;
  transition: background-color 0.3s, border-color 0.3s;
}

button:hover {
  background-color: #444444;
  border-color: #666666;
}

button:disabled {
  background-color: #ccc; /* A gray background color */
  color: #666; /* A darker gray text color */
  cursor: not-allowed; /* Changes the cursor to indicate it's not clickable */
  opacity: 0.6; /* Makes the button appear more faded */
}

input[type="text"],
input[type="number"] {
  font-family: 'Roboto', sans-serif;
  padding: 10px;
  margin: 8px 0;
  box-sizing: border-box;
  border: 1px solid #444444;
  background-color: #1e1e1e;
  color: #e0e0e0;
  border-radius: 4px;
  font-size: 16px;
}

form {
  margin-bottom: 20px;
}

.nft-list {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

@keyframes slideIn {
  from {
    transform: translateX(-100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

.nft-item {
  position: relative;
  background-color: #ffffff;
  border: 1px solid #dddddd;
  border-radius: 8px;
  padding: 15px;
  width: 220px;
  text-align: center;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s, transform 0.3s;
  margin-bottom: 20px;
}

.nft-item.newly-minted {
  animation: slideIn 2.0s linear;
}

.nft-item:hover {
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.75);
  transform: translateY(-5px);
}

.nft-item img {
  max-width: 100%;
  height: auto;
  border-radius: 8px;
  margin-bottom: 25px;
}

.nft-text-center {
  margin-bottom: 10px;
}

.nft-item .nft-name {
  font-family: 'Roboto', sans-serif;
  font-size: 1.4rem;
  font-weight: 700;
  color: #333333;
  margin-bottom: -0.2rem;
}

.nft-item .nft-description {
  font-family: 'Roboto', sans-serif;
  font-size: 1.2rem;
  font-weight: 400;
  color: #555555;
  margin-bottom: 0.5rem;
}

.nft-item .nft-footer {
  display: flex;
  justify-content: space-between;
  bottom: 10px;
  left: 15px;
  right: 15px;
  margin-bottom: 15px;
  margin-top: -10px;
}

.nft-item .nft-tokenId {
  font-family: 'Roboto', sans-serif;
  font-size: 0.75rem;
  font-weight: 300;
  color: #888888;
}

.nft-item .nft-streak {
  font-family: 'Roboto', sans-serif;
  font-size: 0.75rem;
  font-weight: 500;
  color: #888888;
}

.nft-item .nft-telegram {
  font-family: 'Roboto', sans-serif;
  font-size: 0.85rem;
  color: #777;
  margin-top: -20px;
  margin-bottom: -2px;
}
/* Style the NFT link to remove the underline */
.nft-list a {
  text-decoration: none; /* Remove underline */
  color: inherit; /* Inherit the text color from the parent element */
}

.nft-list a:hover {
  color: inherit; /* Ensure text color remains the same on hover */
}

.App p {
  margin: 10px 0;
  color: #b0b0b0;
  font-family: 'Roboto', sans-serif;
  font-size: 1rem;
  line-height: 1.6;
}

.nft-item {
  background-color: #f5f3f3;
  border: 1px solid #333333;
  border-radius: 8px;
  padding: 15px;
  width: 220px;
  text-align: center;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
  transition: box-shadow 0.3s, transform 0.3s;
}

.nft-item p {
  margin: 10px 0;
  color: #111111;
  font-family: 'Roboto', sans-serif;
  font-size: 0.9rem;
}

/* Background SVG image */
.background-image {
  position: fixed; /* Fix the image position so it doesn't scroll */
  bottom: -60%; /* Adjust to peek out from the bottom */
  left: 90%;
  transform: translateX(-50%);
  width: 200%; /* Scale the image up to be gigantic */
  height: auto;
  opacity: 0.05; /* Subtle, semi-transparent effect */
  z-index: -1;
  pointer-events: none;
}

/* Container to hold the Etherscan logo in the top left corner */
.fraxscan-logo-container {
  left: 10px;
  width: 40px;
  height: 40px;
  z-index: 1000; /* Ensure it stays on top */
  align-items: center; /* Vertically centers the Etherscan logo/button */
}

/* Style for the Etherscan logo */
.fraxscan-logo {
  width: 40px;
  height: auto;
  cursor: pointer;
  transition: transform 0.2s ease-in-out;
}

.fraxscan-logo:hover {
  transform: scale(1.1); /* Slightly enlarge on hover */
}

/* Toggle button container */
.toggle-button-container {
  display: flex;
  align-items: center;
  margin-left: auto; /* Push to the far right */
}

/* Toggle button styling */
.toggle-button {
  background-color: #333333;
  color: #ffffff;
  border: 1px solid #444444;
  padding: 10px 20px;
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 1px;
  cursor: pointer;
  border-radius: 4px;
  transition: background-color 0.3s, border-color 0.3s;
  margin-left: 10px;
}
@media (max-width: 700px) {
  .toggle-button {
    padding: 5px 10px;
    font-size: 11px;
  }
}

.toggle-button:hover {
  background-color: #444444;
  border-color: #666666;
}

.title-container {
  flex: 1;
  text-align: left;
  display: flex; /* Added to enable centering */
  align-items: center; /* Vertically centers the title within the container */
}

.title-container .title {
  margin-left: 40px;
  font-size: 2rem; /* Adjust size according to your design */
  color: inherit;
}

@media (max-width: 700px) {
  .title-container .title {
    font-size: 1.5rem; /* Adjust size according to your design */
    margin-left: 10px;
  }
}

@media (max-width: 450px) {
  .title-container .title {
    display: none;
  }
}

.title-container a {
  text-decoration: none; /* Remove underline */
  color: inherit; /* Inherit the text color from the parent element */
}